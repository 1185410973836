.prom_analytics_container {
  height: 100vh;
  overflow: hidden;
  padding: 20px 30px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  .analytics_clear_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 24px;
      display: flex;
      gap: 4px;
      align-items: center;
      .back-icon {
        cursor: pointer;
      }
    }
    .clear_all {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      padding: 0px 10px 0 15px;
      border-radius: 4px;
      background: #00b5ad;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.154px;
    }
  }

  .analytics_dropdown_container {
    display: flex;
    gap: 5px;

    .filter_select {
      width: 100%;
      max-width: 25%;
      min-height: 44px;
      border-radius: 4px !important;

      .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px !important;
        padding: 8px 20px;
        border: 0px !important;
        outline: none;
        height: 44px;

        .ant-select-selection-search,
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.154px;
          overflow: hidden;
          white-space: nowrap;
          max-width: 87%;
          text-overflow: ellipsis;
        }

        .ant-select-selection-search {
          right: 11px;
          border-radius: 8px;
          padding: 0px 8px;
        }

        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
          height: 100%;
          outline: none;
          border-radius: 8px;
          color: rgb(0, 56, 120, 0.35);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.154px;
        }

        .ant-select-selection-item {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 21px */
          letter-spacing: -0.154px;
          border-radius: 8px !important;
        }
      }
    }

    .dropbtn_inactive {
      color: #b9a6a6;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      letter-spacing: -0.154px;
    }

    .doctor_dropdown,
    .patient_dropdown {
      display: flex;
      position: relative;
      flex: 1;
      // max-width: 300px;
      .doctor_dropdown_display {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px 0px 10px;
        // width: 300px;
        height: 42px;
        background-color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
        color: #003878;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: -0.154px;
      }
      .list_search_container {
        top: 44px;
        position: absolute;
        background-color: #f1f1f1;
        width: 100%;
        // overflow-y: scroll;
        padding: 10px;
        // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 5px;
        height: auto;
        max-height: 350px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1;

        /* Webkit browsers */
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .input_search_doctor {
          min-height: 42px;
          width: 100%;
          background-color: #ffffff;
          border-radius: 4px;
          border: none;
          outline: none;
          padding: 0px 5px 0px 5px;
        }
        .display_doctor_list {
          min-height: 42px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background-color: #ffffff;
          border-radius: 4px;
          border: none;

          color: #003878;
          // text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.176px;
          cursor: pointer;
        }
      }
    }

    .dropdown_department,
    .dropdown_hospital {
      flex: 1;
      display: flex;
      // padding-left: 20px;
      // padding-right: 20px;
      position: relative;

      .dropdown_display {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px 0px 10px;
        height: 44px;
        background-color: #ffffff;
        border-radius: 4px;
        color: #003878;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: -0.154px;
        cursor: pointer;
        .dropbtn {
          // color: #000000;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border: none;
          max-width: 284px;
        }
      }
      .dropdown-content {
        display: none;
        top: 44px;
        position: absolute;
        background-color: #f1f1f1;
        width: 100%;
        overflow: auto;
        height: auto;
        max-height: 300px;
        // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        text-align: left;

        /* Add scrollbar styling */
        overflow-y: auto; /* Ensure vertical scrolling */
        scrollbar-width: thin; /* For Firefox */
        scrollbar-color: #888 #f1f1f1; /* For Firefox */

        /* Webkit browsers */
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .items_name {
          color: #003878;
          // text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.176px;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          cursor: pointer;

          &:hover {
            background-color: #ddd;
          }
        }

        .no_data {
          &:hover {
            background-color: #f1f1f1 !important;
          }
        }

        &.show {
          display: block;
        }
      }
    }
  }
  .chat_container {
    height: 80vh;
    flex: 1;
    // width: 80%;
    // padding: 20px;
    margin: 20px;
    .empty_data {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      color: rgb(0, 56, 120, 0.6);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.154px;
    }
  }
}
