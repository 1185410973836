:root {
  --secondary-color: #23bcb5;
  --mid-color: #5ab9b4;
  --heading-color: #616161;
  --sub-text: #888888;
  --light-text: #fff;
  --body-color: #eaf4f3;
  --sidebar-background: #fafafa;
  --border-color: #f5f5f5;
  --card-shadow: 0px 1px 3px #00000029;
  --light-background: #f7f8fa;
  --primary-color: #003873;
}

.doctor_app {
  --app-background: #e0ebf1;
  --secondary-color: #23bcb5;
  --primary-color: #003873;
  --box-shadow: 0px 4px 13px #9bb9c8;
  --sidebar-background: #c1dae8;
  --primary-tag-color: #003e721a;
  --secondary-tag-color: #5ab9b452;
  --dark-color: #163b6d;
  --danger-tag-color: #e1454e;
  //   new colors
  --primary4-color: #dbe9f1;
  --border-default: #ede4e4;
}

.crm_app {
  --text-light-color: #f9f9f9;
  --text-dark-color: #223042;
  --text-secondary-color: #858e93;
  --app-background: #f7f8fb;
  --primary-color: #37c2d8;
  --primary-color-light: rgb(235, 247, 253);
  --success-color: #37cb95;
  --success-color-light: #d2f5e8;
  --warning-color: #eb872a;
  --warning-color-light: #f8e6d5;
  --secondary-color: #d7f3f7;
  --box-shadow: 0px 3px 10px #9bb9c8;
  --dark-color: #213042;
  --sidebar-background: #001529;
  --danger-color: #e1454e;
  --danger-color-light: #ffe2e3;
  --light-color: #b7bcbf;
  --card-border-color: #d9d9d9;
  --divider-color: #c6c9cc;
}
