.prom__patient_container {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .prom__doctor_patient_container {
    display: flex;
    flex-direction: row;
    height: 100%;

    .left_layout {
      max-width: 450px;
      flex: 1;
      padding: 10px;

      .title {
        font-size: 24px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .search-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 5px;
        margin-bottom: 16px;
        background-color: #dbe9f1;
        height: 42px;
        .search_input {
          outline: none;
          border: none;
          background-color: #dbe9f1;
          color: #003878;
          height: 40px;
          // text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
          width: 100%;
          letter-spacing: -0.176px;
        }

        .search_input:focus {
          background-color: white;
        }
        ::placeholder {
          opacity: 1;
        }
        .search_input:focus::placeholder {
          color: transparent;
        }
      }

      .search-container.focused {
        background-color: white;
        border: 1px solid #003878;
      }
      .search-container.focused svg path {
        fill: #003878;
      }
      .patient_card_container {
        overflow: auto;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        /* Add scrollbar styling */
        overflow-y: auto; /* Ensure vertical scrolling */
        scrollbar-width: thin; /* For Firefox */
        scrollbar-color: #888 #f1f1f1; /* For Firefox */

        /* Webkit browsers */
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        .patient_card {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background: #ededed;
          height: 86px;
          margin-bottom: 10px;
          padding: 0px 10px 0px 10px;
          border-radius: 8px;
          cursor: pointer;
          .patient_name {
            color: #003878;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.154px;
          }
          .template_name {
            color: #003878;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            letter-spacing: -0.154px;
          }
          .time_and_type_display_container {
            display: flex;
            align-items: center;
            .time_and_type_display {
              display: flex;
              flex-direction: column;
              align-items: end;
              // justify-content: end;
              .display_time {
                color: #003878;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 24px */
                letter-spacing: -0.176px;
              }
              .display_type {
                // flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 2px;
                padding: 3px;
                height: 23px;
                width: max-content;
                border-radius: 8px;
                background: #fff;
                color: #00b5ad;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: -0.165px;
              }
            }
          }
        }
        .active {
          background-color: #bcdae7;
        }
        .scroll_indicator {
          height: 20px;
        }

        /* Add scrollbar styling */
        overflow-y: auto; /* Ensure vertical scrolling */
        scrollbar-width: thin; /* For Firefox */
        scrollbar-color: #888 #f1f1f1; /* For Firefox */

        /* Webkit browsers */
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }

    .right_layout {
      overflow-y: scroll;
      width: 100%;
      flex: 1;
      background-color: #dbe9f1;

      .loading_data {
        height: 100%;
        padding: 30px;
        display: flex;
        padding-top: 30px;
        justify-content: center;
        text-align: center;
      }

      .no_data {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        justify-content: center;
        color: rgb(0, 56, 120, 0.6);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.154px;
      }
    }
  }
}
