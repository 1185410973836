.prom_template_container {
  height: 100vh;
  overflow: hidden;

  .prom_template_layout_container {
    display: flex;
    flex-direction: row;
    height: 100%;

    .prom_template_left {
      max-width: 450px;
      flex: 1;
      padding: 10px;
      background-color: #ffffff;

      .title_container {
        display: flex;
        justify-content: space-between;

        .title_name {
          color: rgb(22, 59, 108);
          margin-bottom: 12px;
          text-align: center;
          // font-family: "Proxima Nova";
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.264px;
          display: flex;
          gap: 4px;
          align-items: center;
        }
        .add_template {
          cursor: pointer;
        }
      }
      .search-container {
        display: flex;
        align-items: center;
        height: 42px;
        // justify-content: center;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 5px;
        margin-bottom: 16px;
        background-color: #dbe9f1;
        .search_input {
          outline: none;
          width: 100%;
          border: none;
          height: 40px;
          background-color: #dbe9f1;
          color: #003878;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
          letter-spacing: -0.176px;
        }
        .search_input:focus {
          background-color: white;
        }
        ::placeholder {
          opacity: 1;
        }
        .search_input:focus::placeholder {
          color: transparent;
        }
      }

      .search-container.focused {
        background-color: white;
        border: 1px solid #003878;
      }
      .search-container.focused svg path {
        fill: #003878;
      }

      .specialties_container {
        overflow: auto;
        // height: 60vh;
        max-height: 100%;

        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #888 #ffff;

        /* Webkit browsers */
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .specialties_list {
          display: flex;
          justify-content: space-between;
          padding: 12px 20px 12px 12px;
          margin-bottom: 10px;
          height: 80px;
          border-radius: 8px;
          background-color: #ededed;
          cursor: pointer;
          .image_name_display {
            display: flex;
            align-items: center;
            .temp_list_img {
              width: 42px;
              height: 42px;
              margin: 10px;
              border-radius: 10px;
            }
            .display_template_name {
              color: #003878;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 30px */
              letter-spacing: -0.22px;
            }
            .display_department {
              color: #00b5ad;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 24px */
              letter-spacing: -0.176px;
            }
          }
        }
        .active {
          background-color: #e6f7ff;
        }
      }
    }

    .prom_template_right {
      flex: 1;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #888 #f1f1f1;

      /* Webkit browsers */
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .preview_globle_style {
        border: 1px solid #003878;
        cursor: default !important;
        .prom_input {
          cursor: default;
        }
      }

      .right_layout_sub_container {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;

        // width: 100%;
        // padding: 0px 20px;
        // padding-right: 20px;
        gap: 10px;
        height: 100%;
        position: relative;
        //new

        .no_data {
          flex: 1;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          color: rgb(0, 56, 120, 0.6);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.154px;
        }

        .right_layout_sub_temp {
          // width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 20px 20px;
          // padding-bottom: 40px;
          .inactive_state_style {
            color: #6f7e7e;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 21px */
            letter-spacing: -0.154px;
          }
          .select_form_container {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;

            .select_form_type {
              flex: 1;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #ffffff;
              height: 42px;
              padding-left: 20px;
              padding-right: 20px;
              border-radius: 8px;

              .dropdown_display {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .dropbtn {
                  color: #003878;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%; /* 21px */
                  letter-spacing: -0.154px;
                  border: none;
                }
              }
              .dropdown_content_form {
                display: none;
                top: 42px;
                position: absolute;
                background-color: #fff;
                width: 100%;
                overflow: auto;

                // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                text-align: left;
                .item_name {
                  color: #003878;

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%;
                  letter-spacing: -0.176px;
                  padding: 12px 16px;
                  text-decoration: none;
                  display: block;

                  &:hover {
                    background-color: #ddd;
                  }
                }

                &.show {
                  display: block;
                  cursor: pointer;
                  text-align: left;
                }
              }
            }
          }
          .form_container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .treatment_multi_select {
              width: 100%;
              position: relative;
              border-radius: 8px !important;

              .ant-select-selector {
                min-height: 42px;
                display: flex;
                justify-content: space-between;
                border-radius: 8px !important;
                padding: 8px 20px;
                border: 0px !important;
                outline: none;

                .ant-select-selection-placeholder {
                  border: none;
                  height: 42px;
                  display: flex;
                  align-items: center;
                  outline: none;
                  padding-left: 10px;
                  border-radius: 8px;
                  background: #fff;
                  color: rgb(0, 56, 120, 0.35);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%;
                  letter-spacing: -0.154px;
                }

                .ant-select-selection-overflow {
                  gap: 8px !important;
                }

                .ant-select-selection-item {
                  color: #003878 !important;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%; /* 21px */
                  letter-spacing: -0.154px;
                  border-radius: 8px !important;
                }
              }

              // disabled state
              .ant-select-multiple .ant-select-disabled .ant-select-selector {
                background: #fff !important;
              }

              .ant-select-disabled
                .ant-select-multiple
                .ant-select-selection-item {
                border: 0 !important;
              }

              .ant-select:not(.ant-select-customize-input)
                .ant-select-selector {
                border: 0 !important;
              }

              .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
                .ant-select-selector {
                border: none !important;
              }
            }

            .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
              .ant-select-selector {
              background: #fff !important;
            }

            .display_treatement {
              display: flex;
              gap: 10px;

              .treatement_container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;
                background-color: #ffffff;
                height: 42px;
                padding: 0px 5px 0px 20px;

                max-width: 250px;
                min-width: 150px;
                .treatement_name {
                  color: #6f7e7e;
                  text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%;
                  letter-spacing: -0.154px;
                }
                .remove_treatement {
                  padding-top: 5px;
                }
              }
            }
            .tamp_name {
              border: none;
              height: 42px;
              display: flex;
              align-items: center;
              outline: none;
              padding: 0px 10px 0px 20px;
              border-radius: 8px;
              background: #fff;
              color: #003878;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: -0.154px;
            }
            .inactive_state_style_tamp_name {
              border: none;
              height: 42px;
              display: flex;
              align-items: center;
              outline: none;
              padding: 0px 10px 0px 20px;
              border-radius: 8px;
              background: #fff;
              color: #6f7e7e;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: -0.154px;
            }

            .preview_globle_style {
              border: 1px solid #003878;
              cursor: default;
              .prom_input {
                cursor: default;
              }
            }

            .dropdown_container {
              display: flex;
              align-items: center;
              gap: 5px;
              position: relative;

              .dropdown_sub {
                flex: 1;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                height: 40px;
                padding-left: 20px;
                padding-right: 20px;
                position: relative;
                border-radius: 8px;

                // .dropdown {

                // display: inline-block;
                .dropdown_display {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  //   gap: 10px;
                  cursor: pointer;

                  //   &:hover,
                  //   &:focus {
                  //     background-color: #00b5ad;
                  //   }
                  .dropbtn {
                    color: #003878;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; /* 21px */
                    letter-spacing: -0.154px;
                    border: none;
                  }
                }
                // }
              }

              .dropdown-content {
                display: none;
                top: 42px;
                position: absolute;
                background-color: #fff;
                width: 100%;
                overflow: auto;
                height: auto;
                max-height: 300px;
                // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                text-align: left;

                .item_name {
                  color: #003878;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%;
                  letter-spacing: -0.176px;
                  padding: 12px 16px;
                  text-decoration: none;
                  display: block;

                  &:hover {
                    background-color: #ddd;
                  }
                }

                &.show {
                  display: block;
                  text-align: left;
                  cursor: pointer;
                }

                /* Add scrollbar styling */
                overflow-y: auto; /* Ensure vertical scrolling */
                scrollbar-width: thin; /* For Firefox */
                scrollbar-color: #888 #f1f1f1; /* For Firefox */

                /* Webkit browsers */
                &::-webkit-scrollbar {
                  width: 8px;
                }

                &::-webkit-scrollbar-thumb {
                  background: #888;
                  border-radius: 4px;
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }
              }
            }
            .add_check {
              display: flex;
              align-items: center;
              gap: 10px;
              flex-wrap: wrap;
              .input-container {
                max-width: 150px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;
                padding: 0px 5px 0px 5px;
                .input-field {
                  width: 100px;
                  height: 42px;
                  outline: none;
                  border: none;
                  padding-left: 20px;
                  color: #765c5c;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%; /* 21px */
                  letter-spacing: -0.154px;
                  background-color: #fff;
                }

                .delete_days {
                  cursor: pointer;
                  padding-top: 5px;
                }
              }
              .add_new_days {
                display: flex;
                width: 130px;
                height: 42px;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                border-radius: 8px;
                cursor: pointer;
              }
            }
            .doctor-container-display {
              // margin: 20px;
              // padding:  10px;

              border-radius: 8px;

              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              .doctor-dropdown {
                max-width: 250px;
                min-width: 200px;
              }

              .doctor-dropdown select {
                width: 100%;
                padding: 10px;
                //   border: 1px solid #ccc;
                border: none;
                border-radius: 8px;
              }

              .doctor-selected {
                display: flex;
                gap: 10px;

                //   padding-right: 10px;
                .doctor_container {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-radius: 8px;
                  background-color: #ffffff;
                  height: 42px;
                  padding: 0px 5px 0px 5px;

                  max-width: 200px;
                  min-width: 150px;
                  .doctor_name {
                    color: #6f7e7e;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    letter-spacing: -0.154px;
                  }
                  .remove_doctor {
                    padding-top: 5px;
                  }
                }
              }
            }
            .doctor_dropdown {
              display: flex;
              position: relative;
              // max-width: 300px;
              .doctor_dropdown_display {
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding: 0px 10px 0px 10px;
                width: 300px;
                height: 42px;
                background-color: #ffffff;
                border-radius: 8px;
              }
              .list_search_container {
                top: 44px;
                position: absolute;
                background-color: #fff;
                width: 100%;
                overflow-y: scroll;
                padding: 10px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: 5px;
                max-height: 350px;

                .input_search_doctor {
                  height: 42px;
                  width: 100%;
                  background-color: #ffffff;
                  border-radius: 8px;
                  border: none;
                  outline: none;
                  padding: 0px 5px 0px 5px;
                }
                .display_doctor_list {
                  height: 42px;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  background-color: #ffffff;
                  border-radius: 8px;
                  border: none;
                  color: #6f7e7e;
                }
              }
            }
            .create-form {
              width: 100%;
              flex: 1;
              margin: 20px auto;
              border-radius: 8px;
              overflow-y: auto;
              overflow-y: auto;
              max-height: calc(65vh - 50px);
              h2 {
                text-align: left;
                color: #003366;
              }

              .form-title {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                input {
                  flex: 1;
                  padding: 8px;
                  border: 1px solid #ccc;
                  border-radius: 8px;
                }
              }

              .add-title-button {
                margin-left: 10px;
                padding: 8px 12px;
                border: none;
                border-radius: 50%;
                background-color: #003366;
                color: white;
                cursor: pointer;
              }

              .question-container {
                margin-bottom: 20px;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 8px;
                background-color: white;
              }

              .question-header {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                select {
                  flex: 1;
                  margin-right: 10px;
                  padding: 8px;
                  border: 1px solid #ccc;
                  border-radius: 8px;
                }
              }

              .question-header .add-image-button,
              .question-header .delete-button {
                margin-right: 10px;
                padding: 8px;
                border: none;
                background: #003366;
                color: white;
                border-radius: 8px;
                cursor: pointer;
              }

              .options-container {
                margin-top: 10px;
              }

              .option {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                input {
                  margin-right: 10px;
                }
              }

              .add-option {
                padding: 5px;
                border: none;
                background: none;
                color: #003366;
                cursor: pointer;
              }

              .add-question {
                display: block;
                width: 100%;
                padding: 10px;
                margin-top: 20px;
                border: none;
                border-radius: 8px;
                background-color: #003366;
                color: white;
                cursor: pointer;
              }
            }
          }
          .template-form {
            width: 100%;
            display: flex;
            flex: 1;
            flex-direction: column;
            box-sizing: border-box;
            // overflow: hidden;

            .question_container_list {
              height: auto;
              overflow-y: auto;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
          .preview_save_container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            // height: auto;
            // margin: 40px;
            // padding-top: 20px;
            // margin-bottom: 40px;
            .preview_btn,
            .save_temp_btn {
              display: flex;
              justify-content: center;
              gap: 5px;
              align-items: center;
              cursor: pointer;
              border-radius: 8px;
              background: #fff;
              width: 198px;
              height: 42px;
              color: #6f7e7e;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: -0.176px;
            }
          }
          .preview_active {
            // padding-bottom: 20px;
            .preview_btn {
              background: #b8c8d7;
              color: #fff;
            }
            .save_temp_btn {
              background: #00b5ad;
              color: #fff;
            }
          }
        }
      }
    }
    .prom_template_right_action {
      padding: 0px;
    }
  }
}

.tag_input_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.check_days {
  .tag {
    max-width: 150px;
    margin-bottom: 4px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 4px 12px;
    height: 40px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.154px;
  }

  .ant-tag-close-icon {
    color: #003878;
    transform: scale(1.3);
  }

  .added-tags {
    background-color: #fff;
    color: rgb(0, 56, 120, 70%);
  }

  .added-tags span:first-child {
    display: inline-block;
    margin-right: 4px;
  }

  .added-tags__button {
    color: white;
    background-color: rgb(0, 56, 120, 92%);
  }

  .added-tags__button:hover,
  .added-tags__button_disabled {
    background-color: rgb(0, 56, 120, 70%);
  }

  .added-tags__button_disabled {
    cursor: not-allowed !important;
  }

  .added-tags__button_input {
    background-color: #fff;
    color: rgb(0, 56, 120);
  }
}

.ant-select-dropdown-placement-bottomLeft .ant-select-item-option {
  color: #003878;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.176px;
  padding: 12px 16px;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.154px;
}
