.specialties_action_container {
  overflow-y: scroll;
  width: 100%;
  padding: 0px 20px;

  .top_action_container_temp {
    display: flex;
    justify-content: space-between;
    background-color: #bcdae7;
    padding: 10px;
    border-radius: 8px;
    .template_department_container {
      display: flex;
      max-width: 75%;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      // background-color: #ededed;
      .template_name {
        color: #003878;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.264px;
      }
      .department_name {
        color: #1d2929;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.22px;
      }

      .procedure_name_container {
        display: flex;
        flex-direction: row;
        gap: 6px;
        flex-wrap: wrap;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #003878;
        .procedure_name {
          display: inline-flex;
          font-size: 13px;
          font-weight: normal;
        }
      }

      .specialties_action_doctors {
        display: flex;
        gap: 10px;
        .doctor_name {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: #fff;
          width: 183px;
          height: 42px;
          color: #003878;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: -0.176px;
        }
      }
    }
    .specialties_action_days_container_temp {
      width: 205px;
      min-width: 205px;
      max-width: 250px;
      border-radius: 8px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .total_days {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          color: #003878;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.165px;
        }
        span {
          color: #003878;
          text-align: center;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 60px */
          letter-spacing: -0.44px;
        }
      }
      .specialties_action_days {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;
        justify-content: center;
        padding: 0px 5px 10px 5px;
        .days {
          display: flex;
          flex-direction: column;
          width: 48px;
          padding: 6px 0px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #dbe9f1;
          color: #003878;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: -0.22px;
          .days_value,
          .days_text {
            display: block;
          }
          .days_text {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .bottom_action_container {
    // padding: 0px 0px 20px 20px;
    // padding: 0px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .select_patients_container {
      .dropdown_container {
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;

        .dropdown_sub {
          flex: 1;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          height: 42px;
          padding-left: 20px;
          padding-right: 20px;
          position: relative;
          border-radius: 4px;
          .dropdown_display {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .dropbtn {
              color: #b9a6a6;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: -0.154px;
            }
            .active_btn {
              color: #003878;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 21px */
              letter-spacing: -0.154px;
            }
          }
        }

        .dropdown-content {
          display: none;
          top: 42px;
          position: absolute;
          background-color: #f1f1f1;
          width: 100%;
          overflow: auto;

          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
          text-align: left;
          a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;

            &:hover {
              background-color: #ddd;
            }
          }

          &.show {
            display: block;
          }
        }
        .send_button {
          width: 222px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: #fff;
          text-align: center;
          color: #a39e9e;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.198px;
        }
        .active {
          background: #003878;
          color: #e0e0e0;
        }
      }
    }
    .display_form_type {
      border-radius: 8px;
      display: flex;
      height: 42px;
      background: #bcdae7;
      justify-content: center;
      align-items: center;
      color: #003878;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .template_form_view {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      box-sizing: border-box;
      overflow: hidden;

      .question_container_list {
        height: auto;
        overflow-y: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }
  }
}
