.question-sub-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  .question_title {
    color: #003878;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.264px;
  }
  .question_description {
    color: var(--Primary1, #003878);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.176px;

    padding: 0px 0px 8px 0px;
  }
  .multiple_question_container {
    display: flex;

    flex-direction: column;

    .multiple_question_title {
      color: #003878;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      padding: 9px 0px 5px 0px;
      // padding-bottom: 9px;
      letter-spacing: -0.176px;
    }
    .multiple_question_label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 7px 0;
      margin-right: 40px;
      .radio_label_container {
        display: flex;
        justify-content: center;
        gap: 10px;
        color: #003878;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        letter-spacing: -0.198px;

        .input_value {
          width: 24px;
          height: 24px;
        }
        .radio_display {
          background-color: #00b5ad;
          height: 20px;
          width: 20px;
          border-radius: 50px;
        }
        .radio_display_flag {
          background-color: #ffffff;
          border: 1px solid #a7a4ae;
          height: 20px;
          width: 20px;
          border-radius: 50px;
        }
      }
      .option_value {
        color: #00b5ad;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.154px;
        padding-right: 10px;
      }
    }
  }
  .multiple_question_container_EQ-5D-5L {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .multiple_question_title {
      color: #003878;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.176px;
      padding: 10px 0px 10px 0px;
    }
    .multiple_question_label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px 10px 0;
      margin-right: 40px;
      background-color: #00b5ad;
      height: 15px;
      width: 20px;
      border-radius: 50px;
    }
    .multiple_question_label_flag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px 10px 0;
      margin-right: 40px;
      background-color: #d1dcdb;
      height: 15px;
      width: 20px;
      border-radius: 50px;
    }
  }
}
