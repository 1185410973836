.right_layout_sub_patient {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: 100%;
  padding: 0px 20px;

  /* Add scrollbar styling */
  overflow-y: auto; /* Ensure vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */

  /* Webkit browsers */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .top_action_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    // background-color: #bcdae7;
    // height: 250px;
    //   height: 30vh;
    .doctor_form_container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      // align-items: center;
      width: 100%;
      .display_doctor_procedure {
        display: flex;
        gap: 5px;
        width: 100%;
      }
      .display_doctor_procedure * {
        flex: 1;
        display: flex;
        border-radius: 8px;
        background: #dbe9f1;
        height: 42px;
        align-items: center;
        justify-content: center;
        color: #003878;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        padding: 0px 19px;
        font-weight: 600;
        line-height: 150%; /* 21px */
        letter-spacing: -0.154px;
      }
      .form_name_display {
        // flex: 1;
        display: flex;
        border-radius: 8px;
        background: #dbe9f1;
        height: 42px;
        padding: 0px 19px;
        align-items: center;
        justify-content: center;
        color: #003878;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
        letter-spacing: -0.154px;
      }
    }

    .department_and_specialties_container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #bcdae7;
      height: auto;
      border-radius: 8px;
      padding: 10px;

      .name_and_days {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .template_department_doc_container {
        width: 70%;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        .image_name_display {
          display: flex;
          align-items: center;
          .patient_img {
            height: 130px;
            width: 130px;
          }

          .template_name {
            display: flex;
            align-items: baseline;
            gap: 8px;
            color: #003878;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.264px;
          }

          .department_name {
            color: #00b5ad;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.22px;
          }
          .age_gender {
            color: #003878;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.264px;
          }
          .phone_number_display {
            display: flex;
            align-items: center;
            gap: 2px;
            justify-content: flex-start;
            color: #003878;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 22.5px */
            letter-spacing: -0.165px;
          }
        }
        .appointmenttime_patienttype_display {
          display: flex;
          flex-direction: column;
          align-items: end;
          min-width: max-content;
          // gap: 4px;
          .discharge_date_container {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
          }

          .discharge_date {
            color: #003878;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.176px;
          }
          .display_appointmenttime {
            color: #003878;
            font-size: 14px;
            font-style: normal;
            line-height: 150%;
            letter-spacing: -0.176px;
          }

          .prom_completed_ratio_container {
            color: #003878;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.176px;
            .prom_completed_ratio_value {
              font-weight: normal;
            }
          }

          .display_patienttype {
            border-radius: 8px;
            background: #00b5ad;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            height: 23px;

            padding: 0px 10px 0px 10px;
            width: max-content;
            color: #dbe9f1;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.165px;
          }
        }

        .specialties_action_doctors {
          display: flex;
          gap: 10px;
          .doctor_name {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: #fff;
            width: 183px;
            height: 42px;
            color: #003878;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.176px;
          }
        }
      }
      .specialties_action_days_container {
        width: 20%;
        max-width: 40%;
        border-radius: 8px;

        height: auto;
        max-height: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .total_days {
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          h1 {
            color: #003878;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.165px;
          }
          span {
            color: #003878;
            padding: 0;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 60px */
            letter-spacing: -0.44px;
          }
        }
        .specialties_action_days {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          align-items: flex-start;
          //   height: 100%;
          //   flex: 1;
          // justify-content: center;
          //   padding: 0px 5px 0px 5px;
          padding: 5px;
          .days {
            display: flex;
            width: 48px;
            padding: 6px 0px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: #dbe9f1;
            color: #003878;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.22px;
          }
        }
      }
    }

    .active {
      background: #bcdae7;
      // padding: 10px 0px 10px 0px;
      margin: 8px 0px 8px 0px;
      height: auto;
    }
  }

  .down_container {
    // padding: 0px 15px 15px 15px;
    display: flex;
    margin-bottom: 10px;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    position: relative;
    // width: 100%;
    // height: 100%;

    .display_form_type {
      display: flex;
      height: 42px;
      border-radius: 8px;
      background: #bcdae7;
      justify-content: center;
      align-items: center;
      color: #003878;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .select_form_type {
      // flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 8px;
      position: relative;
      .dropdown_display_patient {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;
        .dropbtn_patient {
          color: #000000;
          font-size: 16px;
          border: none;
        }
      }

      .dropdown_content_patient {
        display: none;
        top: 42px;
        position: absolute;
        background-color: #f1f1f1;
        width: 100%;
        overflow: auto;

        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        text-align: left;
        a {
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;

          &:hover {
            background-color: #ddd;
          }
        }

        &.show_patient {
          display: block;
        }
      }
    }

    .frequency_value_container {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-around;

      .frequency_value {
        position: relative;
        cursor: pointer;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 12px;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 28px;
        background: #bcdae7;
        border: 1.5px solid #184c85;

        color: #003878;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.154px;

        .frequency_date {
          display: inline-block;
          font-size: 12px;
        }

        .selected_dot {
          position: absolute;
          top: 22px;
          right: 14px;
          border-radius: 100%;
          display: inline-block;
          height: 20px;
          width: 20px;
        }
      }
      .inactive {
        background: #b8c8d7;
        color: #e7edf0;
        border: none;
      }

      .complete_value {
        background: #00b5ad;
        color: #fff;
        border: none;
      }
    }

    .score_container {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 10px;
      .total_score,
      .interval_score {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 42px;
        background-color: #ffffff;
        border-radius: 8px;
        color: #003878;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .template_form_list {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      box-sizing: border-box;
      overflow: hidden;

      .question_container_list {
        height: auto;
        overflow-y: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }

    .no_responsse_recive {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #b8c8d7;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 60px */
      letter-spacing: -0.44px;
    }
  }
}
