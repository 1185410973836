.auth-layout {
  background-color: #e0ebf1;
  .main-image-wrapper {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-color: #c1dbe8;
  }
  form {
    margin-top: 20px;
  }
}

@primary-color: #4DA9D0;@heading-color: #163B6D;