.prom__doctor_container {
  height: 100vh;
  overflow: hidden;

  .prom__doctor_layout_container {
    display: flex;
    flex-direction: row;
    height: 100%;

    .left_layout {
      max-width: 450px;
      flex: 1;
      padding: 10px;

      .title {
        font-size: 24px;
        display: flex;
        gap: 4px;
        align-items: center;
        margin-bottom: 10px;
        .back-icon {
          cursor: pointer;
        }
      }
      .search-container {
        display: flex;
        align-items: center;
        // justify-content: center;
        height: 42px;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 5px;
        gap: 2px;
        margin-bottom: 16px;
        background-color: #dbe9f1;
        position: relative;
        // input {
        //   outline: none;
        //   border: none;
        // }
        .search_input {
          outline: none;
          border: none;
          height: 40px;
          width: 100%;
          border-radius: 8px;
          // background-color: #00b5ad;
          background-color: #dbe9f1;
          color: #003878;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
          letter-spacing: -0.176px;
        }
        .search_input:focus {
          background-color: white;
        }
        ::placeholder {
          opacity: 1;
        }
        .search_input:focus::placeholder {
          color: transparent;
        }

        .check_container {
          position: absolute;
          top: 42px;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          background-color: #f0ecec;
          padding: 10px 5px 10px 5px;
          width: 190px;
          z-index: 1000;

          .dropdown_label {
            color: #003878;
            text-align: left;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.154px;
            cursor: pointer;
            width: 80%;
            border-bottom: 1px solid #00b5ad;
          }

          .dropdown_label:hover {
            background-color: rgba(13, 207, 197, 0.2);
          }
          // .dropdown_label:last-child {
          //   border-bottom: none;
          // }
        }
      }
      .search-container.focused {
        background-color: white;
        border: 1px solid #003878;
      }
      .search-container.focused svg path {
        fill: #003878;
      }

      .doctor_card_container {
        overflow: auto;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        /* Add scrollbar styling */
        overflow-y: auto; /* Ensure vertical scrolling */
        scrollbar-width: thin; /* For Firefox */
        scrollbar-color: #888 #f1f1f1; /* For Firefox */

        /* Webkit browsers */
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .doctor_card {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background: #ededed;
          gap: 10px;
          // margin: 10px;
          padding: 10px;
          height: 86px;
          margin-bottom: 10px;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;

          .arrow_svg {
            display: inline-block;
            height: 32px;
            width: 32px;
          }
          .dropdown_container {
            display: flex;
            align-items: center;
            gap: 5px;
            position: relative;

            .dropdown_sub {
              flex: 1;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #ffffff;
              height: 45px;
              // padding: 10px;
              padding-left: 20px;
              padding-right: 20px;
              position: relative;
              border-radius: 8px;

              color: #003878;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: -0.176px;
              .dropdown_display {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                // .dropbtn {
                // color: #003878;
                // text-align: center;
                // font-size: 16px;
                // font-style: normal;
                // font-weight: 600;
                // line-height: 150%; /* 24px */
                // letter-spacing: -0.176px;
                // }
              }
            }

            .dropdown-content {
              display: none;
              top: 46px;
              position: absolute;
              background-color: #f1f1f1;
              width: 100%;
              overflow: auto;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 1;

              .procedure_name {
                color: #003878;
                // text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -0.176px;
                padding: 12px 16px;
                text-decoration: none;
                display: block;

                &:hover {
                  background-color: #ddd;
                }
              }

              &.show {
                display: block;
                // display: flex;
                // flex-direction: column;
                // justify-content: left;
                // align-items: flex-start;
                text-align: left;
                cursor: pointer;
              }
            }
          }

          .name_image_display {
            display: flex;
            align-items: center;
            justify-content: center;

            .doctor_img {
              width: 55px;
              height: 55px;
              margin: 10px;
              border-radius: 4px;
            }

            .doctor_name {
              color: #003878;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              letter-spacing: -0.22px;
              margin: 0;
              padding: 0;
            }

            .doctor_specialty {
              color: #1d2929;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 150%;
              letter-spacing: -0.176px;
              margin: 0;
              padding: 0;
            }
          }
        }
        .active {
          // border: 2px solid #1890ff;
          background-color: #dbe9f1;
        }
        .scroll_indicator {
          height: 20px;
        }
      }
    }

    .right_layout {
      flex: 1;
      background-color: #dbe9f1;
      padding-top: 10px;

      .right_layout_sub {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 20px;
        gap: 10px;
        height: 100%;

        .doctor_container {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .selected_doctor {
            display: flex;
            border-radius: 8px;
            flex-direction: column;
            gap: 18px;
            padding: 18px;
            background-color: #bcdae7;

            justify-content: space-between;

            .doc_info {
              display: flex;
              flex-direction: column;
              align-items: start;
            }

            .dropdown_container {
              display: flex;
              align-items: center;
              gap: 5px;
              position: relative;

              .dropdown_sub {
                flex: 1;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                height: 45px;
                // padding: 10px;
                padding-left: 20px;
                padding-right: 20px;
                position: relative;
                border-radius: 8px;

                color: #003878;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: -0.176px;
                .dropdown_display {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  // .dropbtn {
                  // color: #003878;
                  // text-align: center;
                  // font-size: 16px;
                  // font-style: normal;
                  // font-weight: 600;
                  // line-height: 150%; /* 24px */
                  // letter-spacing: -0.176px;
                  // }
                }
              }

              .dropdown-content {
                display: none;
                top: 46px;
                position: absolute;
                background-color: #f1f1f1;
                width: 100%;
                overflow: auto;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;

                .procedure_name {
                  color: #003878;
                  // text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                  letter-spacing: -0.176px;
                  padding: 12px 16px;
                  text-decoration: none;
                  display: block;

                  &:hover {
                    background-color: #ddd;
                  }
                }

                &.show {
                  display: block;
                  // display: flex;
                  // flex-direction: column;
                  // justify-content: left;
                  // align-items: flex-start;
                  text-align: left;
                  cursor: pointer;
                }
              }
            }

            .selected_doctor_profile {
              display: flex;
              align-items: center;
              .doc_profile_img {
                width: 80px;
                height: 80px;
                margin-right: 20px;
                border-radius: 4px;
              }
              .doctor_profile_title {
                color: #003878;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: -0.264px;
                margin: 0;
                padding: 0;
              }
              .doctor_profile_specialty {
                color: #1d2929;
                display: block;
                text-align: left;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%; /* 30px */
                letter-spacing: -0.22px;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
        .bottom_doctor_container {
          // padding: 0px 15px 15px 15px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: 100%;
          overflow: hidden;

          .tab_container {
            display: flex;
            flex: 1;
            flex-direction: column;
            // border-radius: 8px;
            box-sizing: border-box;
            overflow: hidden;
            gap: 10px;
            height: 100%;
            .tab_checkbox_filter {
              display: flex;
              align-items: end;
              justify-content: end;
              padding-bottom: 10px;
              // background-color: red;
              // align-items: center;
              // padding: 10px 20px;
              position: relative;

              .check_container {
                position: absolute;
                top: 42px;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 8px;

                background-color: #fff;
                padding: 0px;
                width: 190px;

                .dropdown_label {
                  color: #003878;
                  padding: 10px 10px;
                  text-align: left;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%;
                  letter-spacing: -0.154px;
                  cursor: pointer;
                  width: 100%;
                  border-bottom: 1px solid #00b5ad;
                }

                .dropdown_label:hover {
                  background-color: rgba(13, 207, 197, 0.2);
                }

                .dropdown_label:first-child {
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                }

                .dropdown_label:last-child {
                  border-bottom: none;
                  border-bottom-right-radius: 8px;
                  border-bottom-left-radius: 8px;
                }
              }

              .filter_all {
                width: 49px;
                height: 41px;
                border-radius: 8px;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .tab-buttons {
              display: flex;
              flex-direction: row;
              // width: 100%;
              padding: 4px 6px;
              background-color: #ccdae7;
              gap: 4px;
              border-radius: 8px;
              .tab-button {
                flex: 1;
                display: flex;
                border-radius: 10px;
                background-color: #b8c8d7;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                // border: 1px solid #616d78;
                align-items: center;
                justify-content: center;
                height: 44px;
                cursor: pointer;
                text-align: center;
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: -0.176px;
                &.active {
                  background-color: #00b5ad;
                  color: #fff;
                }
              }
              // .tab-button:first-child {
              //   border-top-left-radius: 4px;
              //   border-bottom-left-radius: 4px;
              // }

              // .tab-button:last-child {
              //   border-top-right-radius: 4px;
              //   border-bottom-right-radius: 4px;
              // }
            }

            .tab-content {
              overflow-y: auto;
              box-sizing: border-box;
              flex: 1;
              .tab_patient_info_display {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                height: 86px;
                border-radius: 8px;
                background: var(--Primary5, #bcdae7);
                margin: 0px 0px 10px 0px;
                cursor: pointer;
                .display_name_disease {
                  .display_name {
                    color: var(--Primary1, #003878);

                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    letter-spacing: -0.22px;
                  }
                  .display_disease {
                    color: var(--Secondary-2, #1d2929);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.176px;
                  }
                }
                .date_container {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .discharge_date_container {
                    display: flex;
                    flex-direction: column;
                    align-items: end;

                    .discharge_date {
                      color: #003878;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 150%; /* 24px */
                      letter-spacing: -0.176px;
                    }
                    .display_date {
                      color: #003878;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 150%; /* 24px */
                      letter-spacing: -0.176px;
                    }
                  }
                }
              }

              .no_patient_found_container {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                height: 100%;
                .no_patient_found {
                  color: #b8c8d7;
                  text-align: center;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%; /* 60px */
                  letter-spacing: -0.44px;
                }
              }
            }
          }
        }
      }

      .no_data {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        justify-content: center;
        color: rgb(0, 56, 120, 0.6);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.154px;
      }
    }
  }
}
