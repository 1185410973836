.loading_prom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  z-index: 999999999;
  background-color: rgba(46, 48, 51, 0.35); /* 75% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}
